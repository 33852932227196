import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Row, Col } from 'antd';
import { Menus } from 'router';

import menu from 'images/menu.png';
import close from 'images/close.png';
import logo from 'images/logo.jpg';

export default () => {
    const history = useHistory();
    const [expand, setExpand] = React.useState(false);
    const navToRender = Menus.map(item => {
        return (
            <li style={{ textAlign: 'right', marginLeft: '40px' }} key={item.path}>
                <Link style={{ color: window.location.hash === `#${item.path}` ? '#EE5A24' : '#666666', fontSize: '15px', fontWeight: 'bold' }} to={item.path}>
                    {item.title}
                </Link>
            </li>
        );
    });

    const ExpandRender = () => (
        <div onClick={() => setExpand(!expand)} style={{ float: 'right' }}>
            <img alt='' style={{ width: '24px', height: '24px' }} src={expand ? close : menu} />
        </div>
    );

    const ExpandMenu = () => {
        if (expand) {
            return (
                <div>
                    <ul>
                        {Menus.map(item => {
                            return (
                                <li
                                    onClick={() => {
                                        setExpand(false);
                                        history.push(item.path);
                                    }}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '40px',
                                        color: '#666',
                                        borderTop: '1px solid #eeeeee',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                    }}
                                    key={item.path}
                                >
                                    {item.title}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    zIndex: 999,
                    backgroundColor: 'white',
                    padding: '15px',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                    <img style={{ marginRight: '10px' }} width={38} height={38} alt='' src={logo} />
                    <a href='/' rel='noopener noreferrer'>
                        <h1>上海奇诺畅悦康复医疗中心</h1>
                        <h3>chinogutbrainx.com</h3>
                    </a>
                </div>
                <div style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Row align='middle' justify='end'>
                        <Col xs={0} sm={0} md={24}>
                            <ul style={{ display: 'flex', fontSize: '18px', justifyContent: 'flex-end', flexDirection: 'row' }}>{navToRender}</ul>
                        </Col>
                        <Col xs={24} sm={24} md={0}>
                            <ExpandRender />
                        </Col>
                    </Row>
                </div>
            </div>
            <ExpandMenu />
        </>
    );
};
