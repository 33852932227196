import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';

interface IHttpResponse<T = any> {
    code: number;
    msg: string;
    data: T;
}

// 创建axios实例
const request = axios.create({
    baseURL: '/',
    timeout: 5000,
});

request.interceptors.request.use(
    config => {
        // eslint-disable-next-line no-param-reassign
        config.headers.token = '6d131f71f11b677a8f74b2a6a1a69137';
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

// response 拦截器
request.interceptors.response.use(
    (response: AxiosResponse<IHttpResponse>) => {
        if (response.data.code === 200) {
            return response;
        }
        message.error(response.data.msg);
        return Promise.reject(response.data.msg);
    },
    error => {
        return Promise.reject(error);
    },
);

export const httpGet = <T>(url: string) => request.get<IHttpResponse<T>>(url).then(response => response.data.data);
export const httpPost = <T>(url: string, params?: any, config?: AxiosRequestConfig) => {
    return request.post<IHttpResponse<T>>(url, params || {}, config).then(response => response.data.data);
};
