import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Menus } from 'router';
import { Layout } from 'antd';
import Header from 'component/Header';
import Footer from 'component/Footer';

const { Content } = Layout;

export default () => {
    return (
        <>
            <Content>
                <Header />
                <div className='main'>
                    <Suspense fallback={<div />}>
                        {Menus.map(item => (
                            <Route key={item.path} exact component={item.component} path={item.path} />
                        ))}
                    </Suspense>
                </div>
            </Content>
            <Footer />
        </>
    );
};
