import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Main from 'pages/layout';
import Apply from 'pages/apply';
import './App.css';
import './assets/less/index.less';

const App = () => {
    return (
        <Layout>
            <HashRouter>
                <Switch>
                    <Route path='/apply' component={Apply} />
                    <Route path='/' component={Main} />
                </Switch>
            </HashRouter>
        </Layout>
    );
};

export default App;
