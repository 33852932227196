import { lazy } from 'react';

const home = lazy(() => import('pages/home'));
const about = lazy(() => import('pages/about'));
const discovery = lazy(() => import('pages/discovery'));
const course = lazy(() => import('pages/course'));

export const Menus = [
    {
        path: '/',
        title: '首页',
        component: home,
    },
    // {
    //     path: '/discovery',
    //     title: '教练团队',
    //     component: discovery,
    // },
    // {
    //     path: '/course',
    //     title: '精品课程',
    //     component: course,
    // },
    {
        path: '/about',
        title: '关于',
        component: about,
    },
];
