import React from 'react';
import { Row, Col } from 'antd';
import qrcode from 'images/qrcode.jpg';

export default () => {
    return (
        <Row style={{ backgroundColor: '#222222', color: 'white', padding: '30px' }} justify='space-around' align='middle'>
            <Col xs={24} sm={12}>
                <div style={{ lineHeight: '1.8' }}>
                    <p>上海市瑞金二路411号日月光广场伯爵居A座</p>
                    <p>门诊护士台：021-64325136</p>
                    <p>医院办公室：021-64160837</p>
                    {/* <p>邮箱：teenagers_china@163.com</p> */}
                    <p>上海奇诺畅悦康复医疗中心©版权所有</p>
                </div>
            </Col>
            <Col xs={24} sm={12}>
                <Row justify='space-around' align='middle'>
                    <Col span={12}>
                        <img alt='' style={{ width: '80%', height: 'auto' }} src={qrcode} />
                    </Col>
                    <Col span={12}>
                        <img alt='' style={{ width: '80%', height: 'auto' }} src={qrcode} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
