import React from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Input, InputNumber, message, Checkbox } from 'antd';
import { commitApply } from '../../service';
import { IApplyRequest } from '../../types';
import CityForm from './CityForm';

const FilterTypeOptions = [
    { label: '脾气暴躁', value: '脾气暴躁' },
    { label: '爱发脾气', value: '爱发脾气' },
    { label: '容易激动', value: '容易激动' },
    { label: '易激惹', value: '易激惹' },
    { label: '爱哭', value: '爱哭' },
    { label: '闷闷不乐', value: '闷闷不乐' },
    { label: '有轻生念头', value: '有轻生念头' },
    { label: '胡言乱语、自说自话', value: '胡言乱语、自说自话' },
    { label: '电子产品过度使用', value: '电子产品过度使用' },
    { label: '日夜颠倒', value: '日夜颠倒' },
    { label: '打人', value: '打人' },
    { label: '伤害自己', value: '伤害自己' },
    { label: '不服从家长（老师）管教', value: '不服从家长（老师）管教' },
    { label: '和家长发生严重冲突', value: '和家长发生严重冲突' },
    { label: '没有朋友', value: '没有朋友' },
    { label: '交往困难', value: '交往困难' },
    { label: '自信心不足', value: '自信心不足' },
    { label: '厌学', value: '厌学' },
    { label: '生活（学习）没有目标', value: '生活（学习）没有目标' },
    { label: '在校读书', value: '在校读书' },
    { label: '请病假在家', value: '请病假在家' },
    { label: '休学在家', value: '休学在家' },
];

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export default () => {
    const [form] = Form.useForm();

    const history = useHistory();

    const onFinish = (values: IApplyRequest) => {
        commitApply(values).then(
            () => {
                form.resetFields();
                message.info('报名成功');
                history.replace('/');
            },
            () => {
                message.info('报名失败请重试');
            },
        );
    };

    return (
        <div style={{ margin: '20px' }}>
            <Form {...layout} layout='horizontal' form={form} size='large' onFinish={onFinish}>
                <Form.Item label='您的姓名' name='name' rules={[{ required: true, message: '请输入姓名!' }]}>
                    <Input placeholder='请输入您的姓名' />
                </Form.Item>
                <Form.Item label='您的手机号' name='phone' rules={[{ required: true, message: '请输入手机号!' }]}>
                    <Input placeholder='请输入您的手机号' />
                </Form.Item>
                <Form.Item label='您居住的省市' name='city' rules={[{ required: true, message: '请选择您居住的省市!' }]}>
                    <CityForm />
                </Form.Item>
                <Form.Item label='您孩子的姓名' name='childName' rules={[{ required: true, message: '请输入您孩子的姓名!' }]}>
                    <Input placeholder='请输入您孩子的姓名' />
                </Form.Item>
                <Form.Item label='年龄' name='age' rules={[{ required: true, message: '请输入年龄!' }]}>
                    <InputNumber style={{ width: '100%' }} min={1} max={100} placeholder='请输入年龄' />
                </Form.Item>
                <Form.Item name='actuality' label='您孩子目前的情况' rules={[{ required: true, message: '请选择您孩子目前的情况' }]}>
                    <Checkbox.Group options={FilterTypeOptions} />
                </Form.Item>
                <Form.Item label='其他（具体描述）' name='rest'>
                    <Input placeholder='其他（具体描述）' />
                </Form.Item>
                <Form.Item label='您需要解决的问题' name='help'>
                    <Input placeholder='请输入您需要解决的问题' />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24,}}>
                    <Button type='primary' size='large' htmlType='submit' style={{ width: '100%', marginTop: '15px' }}>
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
